<template>
  <div class="box">
    <div class="ribbon ribbon-top-left">
      <span>debug</span>
    </div>
  </div>
</template>

<script>
export default {
    name: "EnvironmentRibbon",
}
</script>

<style>
.box {
    z-index: 999;
    position: fixed;
}

/* common */
.ribbon {
    width: 150px;
    height: 150px;
    overflow: hidden;
    position: absolute;
}

.ribbon::before,
.ribbon::after {
    content: '';
    display: block;
}

.ribbon span {
    position: absolute;
    width: 225px;
    padding: 10px 0;
    background-color: var(--red);
    box-shadow: 0 5px 10px rgba(0, 0, 0, .1);
    color: #fff;
    text-shadow: 0 1px 1px rgba(0, 0, 0, .2);
    text-transform: uppercase;
    text-align: center;
}

/* top left*/
.ribbon-top-left {
    top: -10px;
    left: -10px;
}

.ribbon-top-left::before,
.ribbon-top-left::after {
    border-top-color: transparent;
    border-left-color: transparent;
}

.ribbon-top-left::before {
    top: 0;
    right: 0;
}

.ribbon-top-left::after {
    bottom: 0;
    left: 0;
}

.ribbon-top-left span {
    right: -25px;
    top: 30px;
    transform: rotate(-45deg);
}
</style>