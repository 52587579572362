const tributaryRoutes = [
    {
        path: "solicitud-inspeccion",
        name: "Solicitud-inspeccion",
        component: () => import("@/views/tributary/InspectionRequest/ViewInspectionRequest.vue"),
        meta: {
            requiresAuth: true,
            breadcrumb: "Solicitar Inspección",
            title: "Solicitar Inspección",
        }

    },{
        path:"establecimientos",
        name:"Establecimientos",
        component:()=> import("@/views/tributary/establishments/ViewEstablisments.vue"),
        meta: {
            requiresAuth: true,
            breadcrumb: "Establecimientos",
            title: "Establecimientos",
        }
    },
    {
        path:"solicitudes-declaracion-impuestos",
        name:"Solicitudes-declaracion-impuestos",
        component:()=>import("@/views/tributary/TaxDeclarationRequest/ViewTaxDeclarationRequests.vue"),
        meta:{
            breadcrumb:"Solicitudes de declaración de impuestos",
            title:"Solicitudes de declaración de impuestos"
        }
    }
]

export {
    tributaryRoutes
}