
const authRoutes = [
    {
        path: "/login",
        name: "Login",
        component: () => import("@/views/auth/ViewLogin.vue"),
    },{
        path: "/signup",
        name: "SignUp",
        component: () => import("@/views/auth/ViewSignUp.vue"),
    },{
        path: "/recuperar-contrasena",
        name: "ForgotPassword",
        component: () => import("@/views/auth/ViewForgotPassword.vue"),
    },{
        path: "/registrar-datos-cuenta",
        name: "Registrar-datos-cuenta",
        props: true,
        component: () => import("@/views/auth/ViewRegister.vue"),
        meta: {
            title: "Registrar cuenta",
        }
    },{
        path: "/recuperar-contrasena",
        name: "Recuperar-contrasena",
        props: true,
        component: () => import("@/views/auth/ViewRecoveryPassword.vue"),
        meta: {
            title: "Recuperar contraseña",
        }
    },{
        path: "/validar-cuenta",
        name: "Validar-cuenta",
        component: () => import("@/views/auth/ViewVerifyCode.vue"),
        meta: {
            title: "Validar cuenta",
        }
    },
]

export {
    authRoutes
}