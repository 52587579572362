import HTTP from "../../services/axios"
import {
  getActiveLoansByDate,
  getPaymentPlan,
  getPaymentPlanDetail,
  getPaymentPlanUser,
  getRequestPaymentPlan,
  getAccountManagement,
  getDetailAccountManagement,
  getUserAccountManagement,
  assingUserToAccount,
  getAssignedAccount,
  getManagementOptions,
  managementRegister,
  getAccountManagementHistory,
  unassingAccounts,
  updatePayPlanRequest
} from "../../services/paths"

export default {
  state() {
    return {
      dataPlan: [],
      errorPlan: false,
      successPlan: false,

      dataPlanDetail: [],
      loadingDetail: false,
      errorPlanDetail: false,
      successPlanDetail: false,

      dataPlanUser: [],
      errorPlanUser: false,
      successPlanUser: false,

      dataPaymentPlan: [],
      errorPaymentPlan: false,
      successPaymentPlan: false,

      sendDataUpdatePlan: [],
      errorUpdatePlan: false,
      successUpdatePlan: false,

      activeLoans: [],

      assignedAccountsManagement:[],
      unassignedAccountsManagement:[],
      detailAcccountManagement:{},
      usersAccountManagement:[],

      assignedAccounts:[],
      managementTypeContact:[],
      managementTypeAnswer:[],
      accountManagementHistory:[]
    }
  },

  mutations: {
    loadingPlan(state) {
      state.errorPlan = false,
        state.successPlan = false,
        state.dataPlan = null
    },
    sendDataPlan(state, data) {
      state.errorPlan = false,
        state.successPlan = true,
        state.dataPlan = data
    },
    errorPlan(state, data) {
      state.errorPlan = true,
        state.successPlan = false,
        state.dataPlan = data
    },

    loadingPlanUser(state) {
      state.errorPlanUser = false,
        state.successPlanUser = false,
        state.dataPlanUser = null
    },
    sendDataPlanUser(state, data) {
      state.errorPlanUser = false,
        state.successPlanUser = true,
        state.dataPlanUser = data
    },
    errorPlanUser(state, data) {
      state.errorPlanUser = true,
        state.successPlanUser = false,
        state.dataPlanUser = data
    },

    loadingPlanDetail(state) {
      state.errorPlanDetail = false,
        state.successPlanDetail = false,
        state.dataPlanDetail = null
    },
    sendDataPlanDetail(state, data) {
      state.errorPlanDetail = false,
        state.successPlanDetail = true,
        state.dataPlanDetail = data
    },
    errorPlanDetail(state, data) {
      state.errorPlanDetail = true,
        state.successPlanDetail = false,
        state.dataPlanDetail = data
    },

    loadingPaymentPlan(state) {
      state.errorPaymentPlan = false,
        state.successPaymentPlan = false,
        state.dataPaymentPlan = null
    },
    sendDataPaymentPlan(state, data) {
      state.errorPaymentPlan = false,
        state.successPaymentPlan = true,
        state.dataPaymentPlan = data
    },
    errorPaymentPlan(state, data) {
      state.errorPaymentPlan = true,
        state.successPaymentPlan = false,
        state.dataPaymentPlan = data
    },
    loadingUpdatePlan(state) {
      state.errorUpdatePlan = false,
        state.successUpdatePlan = false,
        state.dataUpdatePlan = null
    },
    sendDataUpdatePlan(state, data) {
      state.errorUpdatePlan = false,
        state.successUpdatePlan = true,
        state.dataUpdatePlan = data
    },
    errorUpdatePlan(state, data) {
      state.errorUpdatePlan = true,
        state.successUpdatePlan = false,
        state.dataUpdatePlan = data
    },
    setActiveLoans(state, data) {
      state.activeLoans = data
    },
    setAssignedAccountsManagement(state,data){
      state.assignedAccountsManagement = data
    },
    setUnassignedAccountsManagement(state,data){
      state.unassignedAccountsManagement = data
    },
    setDetailAccountManagement(state,data){
      state.detailAcccountManagement = data
    },
    setGetUsersAccountManagement(state,data){
      state.usersAccountManagement = data
    },
    setAssignedAccounts(state,data){
      state.assignedAccounts = data
    },
    setManagementTypeContact(state,data){
      state.managementTypeContact= data
    },
    setManagementTypeAnswer(state,data){
      state.managementTypeAnswer= data
    },
    setAccountManagementHistory(state,data){
      state.accountManagementHistory = data
    }
  },

  actions: {
    async getPaymentPlan({ commit }) {
      commit("loadingPlan")
      try {
        await HTTP.get(getPaymentPlan()).then((user) => {
          const { data: { data } } = user
          commit("sendDataPlan", data)
        })
      } catch (errorGlobal) {
        commit("errorPlan", errorGlobal?.response?.data)
      }
    },

    async getPaymentPlanDetail({ commit }, newData) {
      const { idPlanPago, idTipoCuenta } = newData
      commit("loadingPlanDetail")
      try {
        await HTTP.get(getPaymentPlanDetail(idPlanPago, idTipoCuenta)).then((user) => {
          const { data: { data } } = user
          commit("sendDataPlanDetail", data)
        })
      } catch (errorGlobal) {
        commit("errorPlanDetail", errorGlobal?.response?.data)
      }
    },

    async getPaymentPlanUser({ commit }) {
      commit("loadingPlanUser")
      try {
        await HTTP.get(getPaymentPlanUser()).then((user) => {
          const { data: { data } } = user
          commit("sendDataPlanUser", data)
        })
      } catch (errorGlobal) {
        commit("errorPlanUser", errorGlobal?.response?.data)
      }
    },

    async getRequestPaymentPlan({ commit }) {
      try {
        await HTTP.get(getRequestPaymentPlan()).then((response) => {
          commit("sendDataPaymentPlan", response.data.data)
        })
      } catch (errorGlobal) {
        commit("errorPaymentPlan", errorGlobal?.response.data)
      }
    },

    async updatePayPlanRequest({ commit }, body) {
      commit("loadingUpdatePlan")
      try {
        await HTTP.put(updatePayPlanRequest(), body).then((user) => {
          const { data: { message } } = user
          commit("sendDataUpdatePlan", message)
        })
      } catch (errorGlobal) {
        const response = (errorGlobal.response.status === 304 ? "No se pudo actualizar la solicitud porque ya ha sido verificado o no existe." : errorGlobal?.response?.data)
        commit("errorUpdatePlan", response)
      }
    },
    async fetchActiveLoansByDate({ commit }, date) {
      const response = await HTTP.get(getActiveLoansByDate(date[0], date[1]))
      const data = response.data.data
      commit("setActiveLoans", data)
    },
    async getAccountManagement({commit}){
      const response = await HTTP.get(getAccountManagement())
      commit("setAssignedAccountsManagement", response.data.asignadas)
      commit("setUnassignedAccountsManagement", response.data.noAsignadas)
    },
    async getDetailAccountManagement({commit},data){
      const {idCuenta,idTipoCuenta} = data
      const response = await HTTP.get(getDetailAccountManagement(idCuenta,idTipoCuenta))
      commit("setDetailAccountManagement",response.data.data)
    },
    async getUsersAccountManagement({commit}){
      const response = await HTTP.get(getUserAccountManagement())
      commit("setGetUsersAccountManagement", response.data.data)
    },
    async assignUserToAccounts(_,data){
      await HTTP.post(assingUserToAccount(),data)
    },
    async assignedAccounts({commit}){
      const response = await HTTP.get(getAssignedAccount())
      commit("setAssignedAccounts",response.data.data)
    },
    async getManagementOptions({commit}){
      const response = await HTTP.get(getManagementOptions())
      commit("setManagementTypeContact", response.data.data.tipoContacto)
      commit("setManagementTypeAnswer",response.data.data.tipoRespuesta)
    },
    async managementRegister(_,data){
      await HTTP.post(managementRegister(),data)
    },
    async accountManagementHistory({commit},data){
      const response = await HTTP.get(getAccountManagementHistory(data.idCuenta,data.idTipoCuenta))
      commit("setAccountManagementHistory",response.data.data)
    },
    async unassingAccounts(_,data){
      await HTTP.put(unassingAccounts(),data)
    }
  },
}