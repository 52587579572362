export const adsRoutes = [
  {
      path:'anuncios',
      name:'Anuncios',
      component:()=>import('@/views/ads/ViewAds.vue'),
      meta:{
          requiresAuth: true,
          breadcrumb:"Listado de anuncios",
          title: 'Anuncios'
      }
  },
]