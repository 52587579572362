import HTTP from "../../services/axios"
import { getConstancyRequests, getConstancyReasons, requestConstancy, getConstancyChecker, validateConstancy, updateRequestConstancy } from "../../services/paths";

export default {
    state: {
        constancies: [],
        issuedConstancies: [],
        reasons: [],
        constancyDetail: null,
    },

    mutations: {
        setConstancies(state, data) {
            state.constancies = data;
        },
        setIssuedConstancies(state, data) {
            state.issuedConstancies = data;
        },
        setReasons(state, data) {
            state.reasons = data;
        },
        setConstancyDetail(state, data) {
            state.constancyDetail = data;
        },
    },
    actions: {
        async fetchConstancyRequests({ commit }, idRol) {
            const response = await HTTP.get(getConstancyRequests(idRol));
            commit("setConstancies", response.data.data);
        },
        async fetchConstancyDetail({ commit }, idPayment) {
            const response = await HTTP.get(validateConstancy(idPayment));
            commit("setConstancyDetail", response?.data?.data);
        },
        async fetchIssuedConstancies({ commit }) {
            const response = await HTTP.get(validateConstancy(0));
            commit("setIssuedConstancies", response?.data?.data);
        },
        async fetchConstancyReasons({ commit }) {
            const response = await HTTP.get(getConstancyReasons());
            commit("setReasons", response.data.data);
        },
        async requestConstancy(_, data) {
            await HTTP.post(requestConstancy(), data);
        },
        async getConstancyChecker({ commit }) {
            const response = await HTTP.get(getConstancyChecker())
            commit('setConstancies', response.data.data)
        },
        async updateRequestConstancy(_, body) {
            const response = await HTTP.put(updateRequestConstancy(), body)
            return response.data
        },
    }
}
