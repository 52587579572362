const verifyRoutes = [
    {
        path: "detalle-usuario",
        name: "Detalle-usuario",
        component: () => import("@/views/verify/ViewUserData.vue"),
        meta: {
            requiresAuth: true,
            breadcrumb: "Detalle de usuario",
            title: "Detalle de usuario",
        }
    },
    {
        path:'solicitudes-verificacion',
        name:'Solicitudes-de-verificacion',
        component:() => import("@/views/verify/ViewRequestVerify.vue"),
        meta:{
            requiresAuth: true,
            title: "Solicitudes de verificación",
        }
    }
]

export {
    verifyRoutes
}