import HTTP from "../../services/axios"
import { getAccountDataPublic, getRequestDataPublic, getDataPaymentLink } from "../../services/paths";

export default {
    state: {
        account: {},
        request: {},
        paymentLink: {},
        filesList:[],
        filterFilesList:[]
    },

    mutations: {
        setAccount(state, data) {
            state.account = data;
        },
        setRequest(state, data) {
            state.request = data;
        },
        setPaymentLink(state, data) {
            state.paymentLink = data;
        },
        setFileList(state,data){
            let campos = new Set()
            state.filesList.push(data)
            state.filterFilesList = state.filesList.filter(f=>{
                if(campos.has(f.idCampo)){
                    return false
                  }else{
                    campos.add(f.idCampo)
                    return true
                  }
            })
        },
        ///Elimina la foto del arreglo de 
        delFilesList(state,index,id){
            state.filterFilesList.map(f=>{
                if(f.idCampo == id){
                    if(f.docsFiles.length > 0){
                        f.docsFiles.splice(index,1)
                    }
                }
            })
        },
        ///Elimina el objeto del arreglo si no existen imagenes en la propiedad docsFiles del objeto
        deleteFilesFromList(state,id){
            state.filterFilesList = state.filterFilesList.filter(f => f.idCampo !== id)
        },
        ///Resetea las variables de los archivos
        resetFilesProviders(state){
            state.filesList = []
            state.filterFilesList = []
        }
    },

    actions: {
        async fetchPublicAccount({ commit }, account) {
            const response = await HTTP.get(getAccountDataPublic(account.id));
            const data = response.data.data;
            commit("setAccount", data);
        },
        async fetchPublicRequest({ commit }, request) {
            const response = await HTTP.get(getRequestDataPublic(request.id));
            const data = response.data.data;
            commit("setRequest", data);
        },
        async fetchDataPaymentLink({ commit }, request) {
            const response = await HTTP.get(getDataPaymentLink(request.id));
            const data = response.data.data;
            commit("setPaymentLink", data);
        },
        saveFilesProviders({commit},data){
            commit("setFileList",data)
        },
        deleteFilesProviders({commit},index,id){
            commit("delFilesList",index,id)
        },
        deleteFromList({commit},id){
            commit("deleteFilesFromList",id)
        },
        resetFilesProviders({commit}){
            commit("resetFilesProviders")
        }
    },
    getters:{
        getFilesList:state=>{
            return state.filterFilesList
        }
    }
}
