const rootRoutes = [
    {
        path: "logs",
        name: "Logs",
        component: () => import("@/views/root/ViewLogs.vue"),
        meta: {
            requiresAuth: true,
            breadcrumb: "Logs",
            title: "Logs",
        }
    },
    {
        path: "menus",
        name: "Menu",
        component: () => import("@/views/root/ViewMenu.vue"),
        meta: {
            requiresAuth: true,
            breadcrumb: "Menus",
            title: "Menus",
        }
    },
    {
        path: "movimientos",
        name: "Movimientos-santa-tecla-digital",
        component: () => import("@/views/root/ViewMovements.vue"),
        meta: {
            requiresAuth: true,
            breadcrumb: "Movimientos Santa Tecla Digital",
            title: "Movimientos Santa Tecla Digital",
        }
    },
    {
        path:"recibos-cementerio",
        name:"Recibos-cementerio",
        component:()=> import("@/views/root/ViewRecibosCementerio.vue"),
        meta:{
            requiresAuth: true,
            breadcrumb: "Recibos Cementerio",
            title: "Recibos Cementerio",
        }
    },
]
export {
    rootRoutes
}

