import HTTP from "../../services/axios"
import {
  getSolvency,
  detailsSolvency,
  updateSolvencyByChecker,
  getSolvencyRequest,
  getAssociatedAccount
} from "../../services/paths"

export default {
  state() {
    return {
      data: [],
      loading: false,
      error: false,
      success: false,

      dataSolvency: [],
      dataHistory: [],
      loadingSolvency: false,
      errorSolvency: false,
      successSolvency: false,

      dataChecker: [],
      loadingData: false,
      loadingChecker: false,
      errorChecker: false,

      dataCheckerUpdate: [],
      loadingDataUpdate: false,
      loadingCheckerUpdate: false,
      errorCheckerUpdate: false,

      dataAccount: [],
      loadingdataAccount: false,
      successAccount: false,
      errordataAccount: false,
    }
  },

  mutations: {
    loading(state) {
      state.error = false,
        state.loading = true,
        state.success = false,
        state.data = null
    },
    sendData(state, data) {
      state.error = false,
        state.success = true,
        state.loading = false,
        state.data = data
    },
    error(state, data) {
      state.error = true,
        state.loading = false,
        state.success = false,
        state.data = data
    },

    loadingDetails(state) {
      state.errordataAccount = false,
        state.loadingdataAccount = true,
        state.successAccount = false,
        state.dataAccount = null
    },
    sendDataDetails(state, data) {
      state.errordataAccount = false,
        state.successAccount = true,
        state.loadingdataAccount = false,
        state.dataAccount = data
    },
    sendDataHistory(state, data) {
      state.dataHistory = data
    },
    errorDetails(state, data) {
      state.errordataAccount = true,
        state.loadingdataAccount = false,
        state.successAccount = false,
        state.dataAccount = data
    },
    loadingSolvency(state) {
      state.errorSolvency = false,
        state.loadingSolvency = true,
        state.successSolvency = false,
        state.dataSolvency = null
    },
    sendDataSolvency(state, data) {
      state.errorSolvency = false,
        state.successSolvency = true,
        state.loadingSolvency = false,
        state.dataSolvency = data
    },
    errorSolvency(state, data) {
      state.errorSolvency = true,
        state.successSolvency = false,
        state.loadingSolvency = false,
        state.dataSolvency = data
    },
    loadingChecker(state) {
      state.errorChecker = false,
        state.loadingChecker = true,
        state.dataChecker = null
    },
    sendDataChecker(state, data) {
      state.errorChecker = false,
        state.loadingChecker = false,
        state.dataChecker = data
    },
    errorChecker(state, data) {
      state.errorChecker = true,
        state.loadingChecker = false,
        state.dataChecker = data
    },
    loadingPutChecker(state) {
      state.errorCheckerUpdate = false,
        state.loadingDataUpdate = true,
        state.sendDataUpdate = null
    },
    sendDataPutChecker(state, data) {
      state.errorCheckerUpdate = false,
        state.loadingDataUpdate = false,
        state.sendDataUpdate = data
    },
    errorPutChecker(state, data) {
      state.errorCheckerUpdate = true,
        state.loadingDataUpdate = false,
        state.sendDataUpdate = data
    },
  },

  actions: {
    async getSolvency({ commit }, id) {
      commit("loading")
      try {
        await HTTP.get(getSolvency(id)).then((user) => {
          const { data: { data } } = user
          commit("sendData", data)
        })
      } catch (errorGlobal) {
        commit("error", errorGlobal?.response?.data)
      }
    },

    async fetchSolvencies({ commit }, idRol) {
      commit("loadingSolvency")
      try {
        await HTTP.get(detailsSolvency(idRol)).then((response) => {
          console.log(response);
          const { data: { data } } = response
          commit("sendDataSolvency", data)
        })
      } catch (errorGlobal) {
        commit("errorSolvency", errorGlobal?.response?.data)
      }
    },

    /* async getAccountRequestSolvency({ commit }, data) { */
    async getAssociatedAccount({ commit }, data) {
      commit("loadingDetails")
      const { id } = data
      await HTTP.get(getAssociatedAccount(id)).then(async (response) => {
        if (response.status === 200) {
          commit("sendDataDetails", response.data.data.account)
          commit("sendDataHistory", response.data.data.history)
        } else {
          commit("sendDataDetails", null)
        }
      })
        .catch((err) => {
          commit("errorDetails", err?.response?.data)
          commit("sendDataDetails", null)
        })
    },

    async updateSolvencyByChecker({ commit }, body) {
      commit("loadingPutChecker")
      try {
        await HTTP.put(updateSolvencyByChecker(), body).then((user) => {
          const { data: { message } } = user
          commit("sendDataPutChecker", message)
        })
      } catch (errorGlobal) {
        const response = (errorGlobal.response.status === 304 ? "No se pudo actualizar el registro porque ya ha sido verificado o no existe." : errorGlobal?.response?.data)
        commit("errorPutChecker", response)
      }
    },

    async getSolvencyRequest({ commit }) {
      try {
        await HTTP.get(getSolvencyRequest()).then((response) => {
          commit("sendDataChecker", response.data.data)
        })
      } catch (errorGlobal) {
        commit("errorChecker", errorGlobal?.response.data)
      }
    },
  },
}


