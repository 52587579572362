const supportRoutes = [
  {
      path: "solicitudes-soporte",
      name: "Solicitudes-Soporte",
      component: () => import("@/views/support/ViewSupportRequestList.vue"),
      meta: {
          requiresAuth: true,
          breadcrumb: "Soporte",
          title: "Soporte",
      }
  }
]
export {
  supportRoutes
}

