export const catastroRoutes = [
    {
        path:'solicitudes-constancias-catastro',
        name:'Solicitudes-constancias-catastro',
        component:()=>import('@/views/catastro/ViewCatastroRecords.vue'),
        meta:{
            requiresAuth: true,
            breadcrumb:"Solicitudes de constancias catastrales",
            title:"Solicitudes de constancias catastrales"
        }
    },
    {
        path:'solicitar-constancia',
        name:'Solicitar-constancia',
        component:()=>import('@/views/user/Catastro/ViewCatastroRequest.vue'),
        meta: {
            requiresAuth: true,
            breadcrumb: "Solicitar Constancia Catastral",
            title: "Solicitar Constancia Catastral",
        }
    },
]