import HTTP from "../../services/axios"
import { getCertificationsRequests, getCertificationsTypes, requestCertification, getCertificationCheck, updateRequestCertificate, getPickupPlaces } from "../../services/paths";

export default {
    state: {
        certificationsTypes: [],
        certificationsRequests: [],
        certificationsRequestChecker: [],
        pickupPlaces: []
    },

    mutations: {
        setCertificationsTypes(state, data) {
            state.certificationsTypes = data;
        },
        setCertificationRequests(state, data) {
            state.certificationsRequests = data;
        },
        setCertificationRequestsChecker(state, data) {
            state.certificationsRequestChecker = data;
        },
        setPickupPlaces(state, data) {
            state.pickupPlaces = data;
        },
    },

    actions: {
        async fetchCertificationsTypes({ commit }) {
            const response = await HTTP.get(getCertificationsTypes());
            const data = response.data.data;
            commit("setCertificationsTypes", data);
        },
        async fetchCertificationRequests({ commit }, rolId) {
            const response = await HTTP.get(getCertificationsRequests(rolId));
            const data = response.data.data;
            commit("setCertificationRequests", data);
        },
        async requestCertification(_, data) {
            await HTTP.post(requestCertification(), data);
        },
        async fetchPickupPlaces({ commit }) {
            const response = await HTTP.get(getPickupPlaces());
            const data = response.data.data;
            commit("setPickupPlaces", data);
        },
        async getCertificationCheck({ commit }) {
            const response = await HTTP.get(getCertificationCheck());
            const data = response.data.data;
            commit("setCertificationRequestsChecker", data);
        },
        async updateRequestCertificate(_, body) {
            const response = await HTTP.put(updateRequestCertificate(), body)
            return response.data
        }
    }
}
