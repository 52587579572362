const employeeRoutes = [
  {
    path: "boletas-de-pago",
    name: "Boletas-de-pago",
    component: () => import("@/views/employee/ViewVouchers.vue"),
    meta: {
      requiresAuth: true,
      breadcrumb: "Boletas de pago",
      title: "Boletas de pago",
    },
  },
  {
    path: "consulta-boletas-pago",
    name: "Consulta-boletas-de-pago",
    component: () => import("@/views/employee/ViewVoucherSearch.vue"),
    meta:{
        breadcrumb: "Consulta boletas de pagos",
        title: "Consulta boletas de pagos",
    }
  },
];
export { employeeRoutes };
