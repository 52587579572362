import HTTP from "../../services/axios"
import { getAccountData, getInspectionReason, postRequestInspection, getInspections, getEstablishments } from "../../services/paths"

export default {
    state: {
    // Get Account Data
        accountData: null,
    // Send Request Inspection
        SendReqInspection: null,
    // Get Inspection Reason
        dataInspectionReason: null,
    // Get Inspections
        dataInspection: null,
        establishments: [],
        errorEST: false,
    },
    mutations: {
    // Get Account Data
        setAccountData(state, payload) {
            state.accountData = payload
        },
    // Send Request Inspection
        setSendReqInspection(state, payload) {
            state.SendReqInspection = payload
        },
    // Get Inspection Reason
        setInspectionReason(state, payload) {
            state.dataInspectionReason = payload
        },
    // Send Inspection Request
        setInspection(state, payload) {
            state.dataInspection = payload
        },
        setEstablishments(state, payload) {
            state.establishments = payload;
        },
    },
    actions: {
    // Get Account Data
        async getPaymentGetAccountData({ commit }, data) {
            const { accountId, accountType } = data
            const response = await HTTP.get(getAccountData(accountId, accountType))
            commit("setAccountData", ...response.data.data)
        },
    // Send Request Inspection
        async sendReqInspection({ commit }, body) {
            const response = await HTTP.post(postRequestInspection(), body)
            commit("setSendReqInspection", response.data.data)
        },
    // Get Inspection Reason
        async getInspectionReason({ commit }) {
            const response = await HTTP.get(getInspectionReason())
            commit("setInspectionReason", response.data.data)
        },
    // Get Inspections
        async getInspection({ commit }) {
            const response = await HTTP.get(getInspections())
            commit("setInspection", response.data.data)
        },
        async fetchEstablishments({ commit }) {
            const res = await HTTP.get(getEstablishments());
            //console.log(res.data.data[0]);
            commit("setEstablishments", res.data.data);
        },
    },
}
