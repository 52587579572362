import HTTP from "../../services/axios"
import {
    deleteMenu,
    getLogs,
    getMenuDetail,
    getMenus,
    getSystems,
    saveMenu,
    updateMenu,
    getDashboardMovements,
    getRecibosCementerio,
    getAccounts,
    getAccountDetail
} from "../../services/paths";

export default {
    state: {
        logs: [],
        systems: [],
        menu: [],
        menuDetail: {},
        dataMovements: [],
        accounts: [],
        accountDetail: {},
        recibosCementerio: [],
    },

    mutations: {
        setLogs(state, data) {
            state.logs = data;
        },
        setSystems(state, data) {
            state.systems = data;
        },
        setMenu(state, data) {
            state.menu = data;
        },
        setMenuDetail(state, data) {
            state.menuDetail = data;
        },
        setDataMovements(state,data){
            state.dataMovements = data
        },
        setAccounts(state,data){
            state.accounts = data
        },
        setAccountDetail(state,data){
            state.accountDetail = data
        },
        setRecibosCementerio(state,data){
            state.recibosCementerio = data
        },
    },
    actions: {
        async fetchLogs({ commit }, date) {
            //if date is null, then use current date and save in day, month and year variables
            //else use date object and save in day, month and year variables
            //add two digits to day for example 01, 02 or 12
            const day = date ? ("0" + date.getDate()).slice(-2) : ("0" + new Date().getDate()).slice(-2);
            //add two digits to month for example 01, 02 or 12
            const month = date ? ("0" + (date.getMonth() + 1)).slice(-2) : ("0" + (new Date().getMonth() + 1)).slice(-2);
            const year = date ? date.getFullYear() : new Date().getFullYear();
            const response = await HTTP.get(getLogs(day, month, year));
            const data = response.data.data;
            commit("setLogs", data);
        },
        async fetchSystems({ commit }) {
            const response = await HTTP.get(getSystems());
            const data = response.data.data;
            commit("setSystems", data);
        },
        async fetchMenu({ commit }, systemId) {
            const response = await HTTP.get(getMenus(systemId));
            const data = response.data.data;
            commit("setMenu", data);
        },
        async addNewMenu(_, formData) {
            const response = await HTTP.post(saveMenu(), formData);
            return response.data
        },
        async fetchMenuDetail({ commit }, menuId) {
            const response = await HTTP.get(getMenuDetail(menuId));
            const data = response.data.data[0];
            commit("setMenuDetail", data);
        },
        async updateMenu(_, formData) {
            const response = await HTTP.put(updateMenu(), formData);
            return response.data
        },
        async removeMenu(_, menuId) {
            const response = await HTTP.delete(deleteMenu(menuId));
            return response.data
        },
        async fetchDashboardMovements({commit}, params){
            const {type,from,to} = params
            const response = await HTTP.get(getDashboardMovements(type,from,to))
            commit("setDataMovements", response.data.data)
        },
        async fetchAccounts({commit}, params){
            const response = await HTTP.post(getAccounts(),params)
            commit("setAccounts", response.data.data)
        },
        async fetchAccountDetail({commit}, params){
            const response = await HTTP.post(getAccountDetail(),params)
            commit("setAccountDetail", response.data.data)
        },
        async fetchRecibosCementerio({commit},body){
            const response = await HTTP.post(getRecibosCementerio(),body)
            commit("setRecibosCementerio", response.data.data)
        }
    },
}
