const paymentRoutes = [
  {
    path: "generar-enlace-pagos",
    name: "Generar-enlace-pagos",
    component: () => import("@/views/payment/ViewPaymentsLinks.vue"),
    meta: {
      requiresAuth: true,
      breadcrumb: "Generar Enlaces de Pago",
      title: "Generar Enlaces de Pago"
    }
  },
]

export {
  paymentRoutes
}