<template>
  <div class="whatsapp-button-wrapper">
    <transition name="fade">
      <div v-if="showMessage" class="help-message">
        ¿Necesitas ayuda?
      </div>
    </transition>
    <div class="whatsapp-button-container" @click="openWhatsApp">
      <WhatsAppOutlined class="whatsapp-icon" />
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { WhatsAppOutlined } from '@ant-design/icons-vue';

const showMessage = ref(true);

const openWhatsApp = () => {
  const phoneNumber = '50325001350'; // Reemplaza con tu número de teléfono
  const message = '¡Hola! Necesito ayuda'; // Reemplaza con tu mensaje predeterminado
  const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;

  window.open(url, '_blank');
};

onMounted(() => {
  setTimeout(() => {
    showMessage.value = false;
  }, 10000);
});
</script>

<style scoped>
.whatsapp-button-wrapper {
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: flex;
  align-items: center;
  z-index: 1000;
}

@media (max-width: 992px) {
  .whatsapp-button-wrapper {
    bottom: 100px;
  }
}

.whatsapp-button-container {
  width: 60px;
  height: 60px;
  background-color: #25d366;
  border-radius: 50%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}

.whatsapp-button-container:hover {
  transform: scale(1.1);
}

.whatsapp-icon {
  font-size: 23px;
  color: white;
}

.help-message {
  margin-right: 10px;
  padding: 10px 15px;
  background-color: #f5f7f9;
  color: #43474e;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  font-size: 14px;
  white-space: nowrap;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}
</style>
