const formRoutes = 
[
  {
    path: "respuestas",
    name: "Respuestas",
    component: () => import("@/views/form/ViewFormAnswers.vue"),
    meta: {
      title: "Respuestas",
    }
  }, 
]

export {
  formRoutes
}