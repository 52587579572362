const refRoutes = [
    {
        path: "solicitud-registro-estado-familiar",
        name: "Solicitud-registro-estado-familiar",
        component: () => import("@/views/user/CertificationRequest/ViewCertifications.vue"),
        meta: {
            requiresAuth: true,
            breadcrumb: "Certificaciones",
            title: "Certificaciones",
        }
    },
    {
        path: "solicitud-certificacion",
        name: "Solicitud-certificacion",
        component: () => import("@/views/user/CertificationRequest/ViewRequestCertifications.vue"),
        meta: {
            requiresAuth: true,
            breadcrumb: "Solicitud de certificaciones",
            title: "Solicitud de certificaciones",
        }
    },
]
export {
    refRoutes
}

