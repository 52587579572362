import HTTP from "../../services/axios"
import {
  getAccountRequest,
  getUserData,
  activeUser
} from "../../services/paths"

export default {
  state() {
    return {
      match: [],
      userInfo: null,
      errorUser: false,
      dataValidate: [],
      sendMatch: null,
    }
  },

  mutations: {
    errorValidate(state, data) {
      state.errorValidate = true,
        state.dataValidate = data
    },
    sendMatch(state, data) {
      state.match = data
    },

    setUserInfo(state, data) {
      state.userInfo = data
    }
  },

  actions: {
    //Obtiene las coincidencias de las solicitudes de los contribuyentes
    async getAccountRequest({ commit }, id) {
      try {
        await HTTP.get(getAccountRequest(id)).then((response) => {
          commit("sendMatch", response.data.data[0])
        })
      } catch (errorGlobal) {
        commit("error", errorGlobal?.response.data)
      }
    },

    //API que realiza la consulta de usuarios para activar su cuenta
    async getUserData({ commit }, params) {
        const { doc, type } = params
        const response = await HTTP.get(getUserData(doc,type))
        commit("setUserInfo", response.data.data)
    },

    // Guarda las fotos de los documentos y da de alta al usuario.
    async activeUser(_, formData) {
        const response = await HTTP.post(activeUser(), formData)
        return response.data
    },
  },
}
