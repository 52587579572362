import HTTP from "../../services/axios"
import {
  makePayment,
  getAllPayment,
  getTaxesAccount,
  getTaxesDetails,
  requestSolvency,
  getSolvencyReason,
  getAllAccount,
  getHistoryPayment,
  getSolvencyPayment,
  verifyAccountPayment,
  getDetailsHistoryPayment,
  getRoles,
  getRoleManagement,
  requestPaymentPlan,
  getTaxesNPE,
  getPermissions,
  togglePermission,
  addRole,
  editRole,
  getRoleDetail,
  getReporteDeuda,
  getReporteMora,
  getSuggestions,
  getSolicitudesVerificacion,
  verifyRequest,
  verifyUser,
} from "../../services/paths"
import { addPermission } from "@/services/paths"

export default {
  state() {
    return {
      loading: false,
      data: [],
      permissions: [],
      sendPermissions: [],
      error: false,
      loadingDetail: false,
      dataDetail: [],
      errorDetail: false,
      getAccount: null,
      errorPay: null,
      disconnected: null,
      requestSolvency: null,
      errorRequestSolvency: null,
      getSolvencyReason: null,
      makePayment: null,
      getAllAccount: null,
      sendPlan: null,
      loadingPlan: false,
      errorPlan: false,
      sendNPE: null,
      loadingNPE: false,
      errorNPE: false,
      debtReport: {},
      roleDetail: {},
      reporteMora:[],
      getSuggestionsUser:[],
      solicitudesVerificacion:[],
      
      // Estados refactorizados
      accountDetail: null,
    }
  },

  mutations: {
    setGetSuggestionsUser(state,data){
      state.getSuggestionsUser = data
    },
    setGetSolicitudesVerificacion(state,data){
      state.solicitudesVerificacion = data
    },
    loadingHistoryPayment(state) {
      state.error = false,
        state.loading = true,
        state.data = null
    },
    sendHistoryPayment(state, data) {
      state.error = false,
        state.loading = false,
        state.data = data
    },
    errorHistoryPayment(state, data) {
      state.error = true,
        state.loading = false,
        state.data = data
    },
    loadingDetailHistoryPayment(state) {
      state.errorDetail = false,
        state.loadingDetail = true,
        state.dataDetail = null
    },
    sendDetailHistoryPayment(state, data) {
      state.errorDetail = false,
        state.loadingDetail = false,
        state.dataDetail = data
    },
    errorDetailHistoryPayment(state, data) {
      state.errorDetail = true,
        state.loadingDetail = false,
        state.dataDetail = data
    },
    getAccount(state, data) {
      state.getAccount = data
    },
    getAllAccount(state, data) {
      state.getAllAccount = data
    },
    makePayment(state, data) {
      state.makePayment = data
    },

    getSolvencyReason(state, data) {
      state.getSolvencyReason = data
    },

    getAccountDisconnected(state, data) {
      state.disconnected = data
    },

    getAllAccountDisconnected(state, data) {
      state.disconnected = data
    },

    errorPay(state, data) {
      state.errorPay = data
    },

    error(state, data) {
      state.error = data
    },

    requestSolvency(state, data) {
      state.requestSolvency = data
      state.errorRequestSolvency = false
    },
    errorRequestSolvency(state, data) {
      state.requestSolvency = data
      state.errorRequestSolvency = true
    },
    sendRole(state, data) {
      state.error = false,
        state.loading = false,
        state.data = data
    },
    errorRole(state, data) {
      state.error = true,
        state.loading = false,
        state.data = data
    },
    loadingRole(state) {
      state.error = false,
        state.loading = true,
        state.data = null
    },
    loadingPutRole(state) {
      state.error = false,
        state.loading = true,
        state.data = null
    },
    sendPutRole(state, data) {
      state.error = false,
        state.loading = false,
        state.sendPutRole = data
    },
    errorPutRole(state, data) {
      state.error = true,
        state.loading = false,
        state.data = data
    },
    sendPlan(state, data) {
      state.errorPlan = false,
        state.loadingPlan = false,
        state.sendPlan = data
    },
    errorPlan(state, data) {
      state.errorPlan = true,
        state.loadingPlan = false,
        state.sendPlan = data
    },
    loadingPlan(state) {
      state.errorPlan = false,
        state.loadingPlan = true,
        state.sendPlan = null
    },
    sendNPE(state, data) {
      state.errorNPE = false,
        state.loadingNPE = false,
        state.sendNPE = data
    },
    errorNPE(state, data) {
      state.errorNPE = true,
        state.loadingNPE = false,
        state.sendNPE = data
    },
    loadingNPE(state) {
      state.errorNPE = false,
        state.loadingNPE = true,
        state.sendNPE = null
    },
    setPermissions(state, data) {
      state.permissions = data
    },
    sendPermissions(state, data) {
      state.sendPermissions = data
    },

    setRoleDetail(state, data) {
      state.roleDetail = data
    },
    setDebtReport(state, data) {
      state.debtReport = data
    },
    setReporteMora(state,data){
      state.reporteMora = data
    },

    setAccountDetail(state, data) {
      state.accountDetail = data
    },
  },

  actions: {
    async getPaymentHistory({ commit }) {
      commit("loadingHistoryPayment")
      try {
        await HTTP.get(getHistoryPayment()).then((user) => {
          const { data: { data } } = user
          commit("sendHistoryPayment", data)
        })
      } catch (errorGlobal) {
        commit("errorHistoryPayment", errorGlobal?.response?.data)
      }
    },
    async getSolvencyPayment({ commit }) {
      commit("loadingHistoryPayment")
      try {
        await HTTP.get(getSolvencyPayment()).then((user) => {
          const { data: { data } } = user
          commit("sendHistoryPayment", data)
        })
      } catch (errorGlobal) {
        commit("errorHistoryPayment", errorGlobal?.response?.data)
      }
    },
    async getAllPayment({ commit }, tipo_pago) {
      commit("loadingHistoryPayment")
      try {
        await HTTP.get(getAllPayment(tipo_pago)).then((user) => {
          const { data: { data } } = user
          commit("sendHistoryPayment", data)
        })
      } catch (errorGlobal) {
        commit("errorHistoryPayment", errorGlobal?.response?.data)
      }
    },
    async getDetailPaymentHistory({ commit }, id) {
      commit("loadingDetailHistoryPayment")
      try {
        await HTTP.get(getDetailsHistoryPayment(id)).then((user) => {
          const { data: { data } } = user
          commit("sendDetailHistoryPayment", data)
        })
      } catch (errorGlobal) {
        commit("errorDetailHistoryPayment", errorGlobal?.response?.data)
      }
    },
    async getSolvencyReason({ commit }) {
      try {

        await HTTP.get(getSolvencyReason()).then((reason) => {
          const { data: { data } } = reason
          commit("getSolvencyReason", data)
        })
      } catch (errorGlobal) {
        commit("getSolvencyReason", errorGlobal?.response?.data)
      }
    },
    async getAccount({ commit }) {

      await HTTP.get(getTaxesAccount()).then(async (response) => {
        if (response.status === 200) {
          const accounts = response.data.data;

          commit("getAccount", accounts)
          commit("getAccountDisconnected", true)
        } else {
          commit("getAccount", null)
        }
      })
        .catch((err) => {
          console.log(err)
          commit("error", err?.response?.data)
          commit("getAccount", null)
          commit("getAccountDisconnected", false)
        })
    },


    async getAllAccount({ commit }, params) {
      const { value, type } = params
      await HTTP.get(getAllAccount(value, type)).then(async (response) => {
        if (response.status === 200) {
          commit("getAllAccount", response.data.data[0])
          commit("getAllAccountDisconnected", true)
        } else {
          commit("getAllAccount", null)
        }
      })
        .catch((err) => {
          console.log(err)
          commit("error", err?.response?.data)
          commit("getAllAccount", null)
          commit("getAllAccountDisconnected", false)
        })
    },

    async accountPayed({ commit }, body) {
      let account = {}
      await body.forEach((cuenta, index) => {
        const body = {
          id: cuenta.idAccount
        }
        HTTP.post(verifyAccountPayment(), body)
          .then((res) => {
            if (res?.data?.data[0].PAGADO === 1) {
              cuenta.balance = 0
              cuenta.mora = 0
              cuenta.corriente = 0
              cuenta.multa = 0
              cuenta.intereses = 0
            }
            account[index] = cuenta
          })
      })
      commit("getAccount", account)
    },

    // Obtiene el detalle de la cuenta
    async getAccountDetail({ commit }, id) {
      const response = await HTTP.get(getTaxesDetails(id))
      commit("setAccountDetail", response.data.data)
    },

    //API que genera la solicitud de solvencia
    async requestSolvency({ commit }, body) {
      try {
        await HTTP
          .post(requestSolvency(), body)
          .then((response) => {
            if (response.status === 200) {
              commit("requestSolvency", response.data)
            } else {
              commit("errorRequestSolvency", response.data)
            }
          })
          .catch((err) => {
            commit("errorRequestSolvency", err.response.data)
          })
      } catch (error) {
        commit("errorRequestSolvency", error.response.data)
      }
    },

    //API que genera la solicitud de solvencia
    async makePayment({ commit }, body) {
      try {
        await HTTP
          .post(makePayment(), body)
          .then((response) => {
            commit("makePayment", response.data)
          })
          .catch((err) => {
            console.error("Error ", err)
            commit("makePayment", err.response.data)
          })
      } catch (error) {
        console.error("Error ", error)
        commit("makePayment", error.response.data)
      }
    },

    //API que obtiene los roles
    async getRoles({ commit }) {
      commit("loadingRole")
      try {
        await HTTP.get(getRoles()).then((user) => {
          const { data: { data } } = user
          commit("sendRole", data)
        })
      } catch (errorGlobal) {
        commit("errorRole", errorGlobal?.response?.data)
      }
    },

    async getPermissions({ commit }, roleId) {
      const response = await HTTP.get(getPermissions(roleId))
      commit("setPermissions", response.data?.data || {})
    },

    async togglePermission({ commit }, body) {
      const response = await HTTP.post(togglePermission(), body)
      commit("setPermissions", response.data?.data || {})
    },

    async addNewPermission(_, body) {
      const response = await HTTP.post(addPermission(), body)
      return response.data;
    },

    async addNewRole(_, formData) {
      const response = await HTTP.post(addRole(), formData)
      return response.data;
    },

    async editRole(_, formData) {
      const response = await HTTP.put(editRole(), formData)
      return response.data;
    },

    async fetchRoleDetail({ commit }, roleId) {
      const response = await HTTP.get(getRoleDetail(roleId))
      commit("setRoleDetail", response.data?.data || {})
    },

    //API que administra los roles de usuario
    async getRoleManagement({ commit }, params) {
      commit("loadingPutRole")
      try {
        await HTTP.put(getRoleManagement(), params).then((data) => {
          if (data?.data?.data.length) {
            commit("sendPutRole", data?.data?.data[0])
          } else {
            console.log(data);
            commit("errorUser", data?.data?.message)
          }
        }).catch((e) => {
          commit("errorUser", e?.response?.data?.message)
        })
      } catch (errorGlobal) {
        console.log(errorGlobal);
        commit("errorUser", "Ocurrió un error al obtener los detalles de la cuenta.")
      }
    },

    //API que genera la solicitud de plan de pago
    async requestPaymentPlan({ commit }, body) {
      commit("loadingPlan")
      try {
        await HTTP.post(requestPaymentPlan(), body).then((response) => {
          if (response.status === 200) {
            commit("sendPlan", response.data)
          } else {
            commit("errorPlan", response.data)
          }
        })
          .catch((error) => {
            commit("errorPlan", error.response.data)
          })
      } catch (error) {
        commit("errorPlan", error.response.data)
      }
    },

    //API que realiza la busqueda por NPE
    async getTaxesNPE({ commit }, codigo) {
      commit("loadingNPE")
      try {
        await HTTP.get(getTaxesNPE(codigo)).then((data) => {
          if (data?.data?.data && data?.data?.data.length) {
            commit("sendNPE", data?.data?.data)
          } else {
            commit("errorNPE", data?.data?.message)
          }
        }).catch((e) => {
          commit("errorNPE", e?.response?.data?.message)
        })
      } catch (errorGlobal) {
        commit("errorNPE", "Ocurrió un error al obtener los detalles de la cuenta.")
      }
    },
  
    async getDatosReporteDeuda({ commit }) {
      const response = await HTTP.get(getReporteDeuda())
      commit("setDebtReport", response.data.data)
    },

    async getReporteMora({commit},body){
      const {type, period} = body
      await HTTP.get(getReporteMora(type,period)).then(res =>{
        commit("setReporteMora",res.data.data)
      })
    },

    async getSolicitudVerificacion({commit}){
      const response = await HTTP.get(getSolicitudesVerificacion())
      commit("setGetSolicitudesVerificacion",response.data.data)
      
    },
    async verificacionUsuario(_,data){
      await HTTP.put(verifyUser(),data)
    },
    async solicitudVerificacion(_,data){
      await HTTP.post(verifyRequest(),data)
    },
    async getSuggestionsUser({commit}){
      const res = await HTTP.get(getSuggestions())
      commit("setGetSuggestionsUser",res.data.data)
      console.log(res)
    }
  }
}