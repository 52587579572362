<template>
  <a-config-provider :locale="locale">
    <title>Alcaldía de La Libertad Sur, Distrito Santa Tecla</title>
    <FloatingWhatsAppButton />
    <EnvironmentRibbon v-if="isDevelopmentMode" />
    <vue3-progress />
    <router-view />
  </a-config-provider>
</template>

<script>
import { computed } from "vue"
import esES from "ant-design-vue/es/locale/es_ES"
import FloatingWhatsAppButton from "@/components/FloatingWhatsAppButton.vue"
import EnvironmentRibbon from "./components/EnvironmentRibbon.vue"

export default {
  name: "App",
  components: {
    EnvironmentRibbon,
    FloatingWhatsAppButton
  },
  setup() {

    const isDevelopmentMode = computed(() => {
      return process.env.NODE_ENV !== "production" ? true : false
    })

    return {
      isDevelopmentMode
    }
  },
  data() {
    return {
      locale: esES,
    }
  }
}
</script>