//Others
import App from "./App.vue"
import store from "./store"
import router from "./router"
import { createApp } from "vue"
import Antd from "ant-design-vue"
import VueTheMask from "vue-the-mask"
import CKEditor from '@ckeditor/ckeditor5-vue';
import { handleError } from "./utils/error-handler"
import globalComponents from "./global-components"
import Vue3Progress from 'vue3-progress';

// Styles
import './assets/styles/main.less'
import 'bootstrap/dist/css/bootstrap-utilities.css'

const app = createApp(App)

// set error handler
app.config.errorHandler = (err, instance, info) => handleError(err, instance, info)

// register global components
globalComponents.forEach((component) => {
    app.component(component.name, component.component)
})

app.use(Vue3Progress, {
    color: '#40A9E1',
    thickness: '4px',
    height: "5px"
  });

window.$progress = app.config.globalProperties.$progress;

//Statement
app.use(store)
    .use(Antd)
    .use(router)
    .use(VueTheMask)
    .use(CKEditor)
    .mount("#app")

    