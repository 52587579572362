import HTTP from "../../services/axios"
import {
  getLicence,
  requestLicence,
  getLicenceRequest,
  updateLicenceByChecker,
  getLicenseDetail,
} from "../../services/paths"

export default {
  state() {
    return {
      requestLicence: null,
      errorRequestLicence: null,
      dataChecker: [],
      errorChecker: false,
      sendDataUpdate: null,
      errorCheckerUpdate: false,
      data: [],
      loading: false,
      error: false,
      success: false,

      licenseDetail: {},
      errorLicenseDetail: false
    }
  },

  mutations: {
    requestLicence(state, data) {
      state.requestLicence = data
      state.errorRequestLicence = false
    },
    errorRequestLicence(state, data) {
      state.requestLicence = data
      state.errorRequestLicence = true
    },
    sendDataChecker(state, data) {
      state.errorChecker = false,
      state.dataChecker = data
    },
    errorChecker(state, data) {
      state.errorChecker = true,
      state.dataChecker = data
    },
    sendDataPutChecker(state, data) {
      state.errorCheckerUpdate = false,
      state.sendDataUpdate = data
    },
    errorPutChecker(state, data) {
      state.errorCheckerUpdate = true,
      state.sendDataUpdate = data
    },
    loading(state) {
      state.error = false,
        state.loading = true,
        state.success = false,
        state.data = null
    },
    sendData(state, data) {
      state.error = false,
        state.success = true,
        state.loading = false,
        state.data = data
    },
    error(state, data) {
      state.error = true,
        state.loading = false,
        state.success = false,
        state.data = data
    },

    setLicenseDetail(state, licenseDetail) {
      state.licenseDetail = licenseDetail
      state.errorLicenseDetail = false
    },
    licenseDetailError(state, data) {
      state.licenseDetail = data
      state.errorLicenseDetail = true
    }
  },

  actions: {
    async requestLicence({ commit }, body) {
      try {
        await HTTP.post(requestLicence(), body).then((response) => {
            response.status === 200 ? commit("requestLicence", response.data) : commit("errorRequestLicence", response.data)
          })
          .catch((err) => {
            commit("errorRequestLicence", err.response.data)
          })
      } catch (error) {
        commit("errorRequestLicence", error.response.data)
      }
    },
    async getLicenceRequest({ commit }) {
      try {
        await HTTP.get(getLicenceRequest()).then((response) => {
          commit("sendDataChecker", response.data.data)
        })
      } catch (errorGlobal) {
        commit("errorChecker", errorGlobal?.response.data)
      }
    },
    async updateLicenceByChecker({ commit }, body) {
      commit("loadingPutChecker")
      try {
        await HTTP.put(updateLicenceByChecker(), body).then((user) => {
          const { data: { message } } = user
          commit("sendDataPutChecker", message)
        })
      } catch (errorGlobal) {
        const response = (errorGlobal.response.status === 304 ? "No se pudo actualizar el registro porque ya ha sido verificado o no existe." : errorGlobal?.response?.data)
        commit("errorPutChecker", response)
      }
    },
    async getLicence({ commit }, id) {
      commit("loading")
      try {
        await HTTP.get(getLicence(id)).then((user) => {
          const { data: { data } } = user
          commit("sendData", data)
        })
      } catch (errorGlobal) {
        commit("error", errorGlobal?.response?.data)
      }
    },

    async getLicenseDetail({ commit }, id) {
      try {
        await HTTP.get(getLicenseDetail(id)).then((response) => {
          const { data: { data } } = response
          commit("setLicenseDetail", data)
        })
      } catch (errorGlobal) {
        commit("licenseDetailError", errorGlobal?.response?.data)
      }
    }
  },
}


