import HTTP from "../../services/axios"
import {
  getTaxpayerReceipt,
  getIncomeDifferences,
  getTaxpayerReceiptDetail,
  getIncomesPerPeriodsMoa
} from "../../services/paths"

export default {
  state() {
    return {
      payments: [],	
      receipts: [],
      receiptDetail: null,
      incomesDataMoa:[],
      totalPagos:0
    }
  },

  mutations: {
    setPayments(state, data) {
      state.payments = data
    },
    setReceipts(state, data) {
      state.receipts = data
    },
    setReceiptDetail(state, data) {
      state.receiptDetail = data
    },
    setIncomesDataMoa(state,data){
      state.incomesDataMoa = data
    },
    setTotalPagos(state,data){
      state.totalPagos = data
    },
  },

  actions: {
    async getPaymentDifference({ commit }, date) {
      let response = await HTTP.get(getIncomeDifferences(date[0], date[1]))
      commit("setPayments", response.data.data)
    },
    async getTaxpayerReceiptHistory({ commit }, body) {
      let response = await HTTP.post(getTaxpayerReceipt(), body)
      commit("setReceipts", response.data.data)
    },
    async getTaxpayerReceiptHistoryDetail({ commit }, id) {
      let response = await HTTP.get(getTaxpayerReceiptDetail(id))
      commit("setReceiptDetail", response.data.data)
    },
    async getIncomesDataMoa({commit},data){
      let {from,to} = data
      let response = await HTTP.get(getIncomesPerPeriodsMoa(from,to))
        commit("setIncomesDataMoa",response.data.data.conceptos)
        commit("setTotalPagos",response.data.data.total)
    }
  },
}


