import AH1 from "./widgets/headings/AH1.vue";
import AH2 from "./widgets/headings/AH2.vue";
import AH3 from "./widgets/headings/AH3.vue";
import AH4 from "./widgets/headings/AH4.vue";
import AH5 from "./widgets/headings/AH5.vue";

export default [
  {
    name: "AH1",
    component: AH1
  },
  {
    name: "AH2",
    component: AH2
  },
  {
    name: "AH3",
    component: AH3
  },
  {
    name: "AH4",
    component: AH4
  },
  {
    name: "AH5",
    component: AH5
  }
]