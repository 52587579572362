

const publicRoutes = [
    {
        path: "/",
        name: "Home",
        component: () => import("@/views/ViewHome.vue"),
        meta: {
            title: "Inicio",
        }
    },{
        path: "/servicios",
        name: "Services",
        component: () => import("@/views/public/ViewServices.vue"),
        meta: {
            title: "Servicios",
        }
    },{
        path: "/pagar-cuenta/:id",
        name: "Pagar-cuenta",
        component: () => import("@/views/public/ViewPayAccount.vue"),
        meta: {
            title: "Pagar cuenta",
        }
    },{
        path: "/pagar-qr/:id",
        name: "Pagar-qr",
        component: () => import("@/views/public/ViewPayAccount.vue"),
        meta: {
            title: "Pagar cuenta",
        }
    },{
        path: "/pagar-solicitud/:id",
        name: "Pagar-solicitud",
        component: () => import("@/views/public/ViewPayRequest.vue"),
        meta: {
            title: "Pagar solicitud",
        }
    },{
        path: "/enlace-pago/:id",
        name: "Enlace-pago",
        component: () => import("@/views/public/ViewPaymentLink.vue"),
        meta: {
            title: "Pagar solicitud",
        }
    },{
        path: "/pagoenlinea",
        name: "Pago-en-linea",
        component: () => import("@/views/public/ViewSearchAccount.vue"),
        meta: {
            title: "Buscar estado de cuenta",
        }
    },{
        path:"/actualizacion-proveedores",
        name:"actualizacion-de-proveedores",
        component: () => import("@/views/public/ViewProvidersUpdate.vue"),
        meta: {
            title:"Actualización de proveedores"
        }
    },
    {
        path:"/recibo/:id",
        name:"Recibo",
        component:()=> import("@/views/public/ViewReceipt.vue"),
        meta: {
            title:"Recibo"
        },
        beforeEnter:(to,from,next)=>{
            const id = to.params.id
            to.meta.title = `Recibo #${id}`
            document.title = to.meta.title
            next()
        }
    },
    {
        path: "/validar-solvencia",
        name: "Validar-Solvencia",
        component: () => import("@/views/public/ViewSolvencyStatus.vue"),
        meta: {
            title: "Validar solvencia",
        }
    },
    {
        path: "/historial-licencias",
        name: "Historial-Licencias",
        component: () => import("@/views/public/ViewLicencesHistory.vue"),
        meta: {
            title: "Historial licencias",
        }
    },
    {
        path: "/validar-cnc",
        name: "Validar-cnc",
        component: () => import("@/views/public/ViewNonTaxpayerVoucher.vue"),
        meta: {
            title: "Validar cnc",
        }
    },
    {
        path:"/validar-catastro",
        name:"Validar-catastro",
        component:()=> import("@/views/public/ViewCatastroConstancyVoucher.vue"),
        meta:{
            title:"Validar catastro"
        }
    }
]

export {
    publicRoutes
}