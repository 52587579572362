const portfolioRoutes = [
    {
        path: "verificacion-cartera",
        name: "Solicitudes-cartera",
        component: () => import("@/views/portfolio/ViewTable.vue"),
        meta: {
            requiresAuth: true,
            breadcrumb: "Verificacion cartera",
            title: "Verificacion cartera",
        }
    },
    {
        path: "solicitudes-licencias",
        name: "Solicitudes-licencias",
        component: () => import("@/views/portfolio/ViewLicenceManagement.vue"),
        meta: {
            requiresAuth: true,
            breadcrumb: "Solicitudes de licencias",
            title: "Solicitudes de licencias",
        }
    },
    {
        path: "solvencias-emitidas",
        name: "Solvencias-emitidas",
        component: () => import("@/views/portfolio/ViewSolvencies.vue"),
        meta: {
            requiresAuth: true,
            breadcrumb: "Solvencias emitidas",
            title: "Solvencias emitidas",
        }
    },
    {
        path: "historial-pagos-solvencia",
        name: "Historial-pagos-solvencia",
        component: () => import("@/views/portfolio/ViewPaymentSolvency.vue"),
        meta: {
            requiresAuth: true,
            breadcrumb: "Historial de pagos de solvencia",
            title: "Historial de pagos de solvencia",
        }
    },
    {
        path: "planes-pago",
        name: "Planes-pago",
        component: () => import("@/views/portfolio/ViewPaymentPlan.vue"),
        meta: {
            requiresAuth: true,
            breadcrumb: "Planes de pago",
            title: "Planes de pago"
        }
    },
    {
        path: "solicitudes-planes-pago",
        name: "Solicitudes-planes-pago",
        component: () => import("@/views/portfolio/ViewRequestPlan.vue"),
        meta: {
            requiresAuth: true,
            breadcrumb: "Solicitud de planes de pago",
            title: "Solicitud de planes de pago"
        }
    },
    {
        path: "prestamos-activos",
        name: "Prestamos-activos",
        component: () => import("@/views/portfolio/ViewActiveLoans.vue"),
        meta: {
            requiresAuth: true,
            breadcrumb: "Prestamos activos",
            title: "Prestamos activos"
        }
    },
    {
        path:"gestion-cuentas",
        name:"Gestion-cuentas",
        component:()=> import("@/views/portfolio/ViewAccountsManagement.vue"),
        meta: {
            requiresAuth: true,
            breadcrumb: "Gestión de cuentas",
            title: "Gestión de cuentas"
        }
    },
    {
        path:"gestion-cuentas-asignadas",
        name:"Gestion-cuentas-asignadas",
        component:()=> import("@/views/portfolio/ViewAssignedAccounts.vue"),
        meta: {
            requiresAuth: true,
            breadcrumb: "Cuentas asignadas",
            title: "Cuentas asignadas"
        }
    },
    {
        path:"licencias-emitidas",
        name:"Licencias-emitidas",
        component:()=> import("@/views/portfolio/ViewIssuedLicences.vue"),
        meta:{
            requiresAuth: true,
            breadcrumb: "Licencias Emitidas",
            title: "Licencias Emitidas"
        }
    }
]

export {
    portfolioRoutes
}