const adminRoutes = [
    {
        path: "roles-usuario",
        name: "Roles-usuario",
        component: () => import("@/views/admin/ViewUserRoles.vue"),
        meta: {
            requiresAuth: true,
            breadcrumb: "Roles de usuario",
            title: "Roles de usuario",
        }
    },
    {
        path: "roles-permisos",
        name: "Roles-permisos",
        component: () => import("@/views/admin/ViewRoles.vue"),
        meta: {
            requiresAuth: true,
            title: "Roles y permisos",
        }
    },
    {
        path: "reporte-deudas",
        name: "Reporte-de-deudas",
        component: () => import("@/views/admin/ViewReporteDeuda.vue"),
        meta: {
            requiresAuth: true,
            breadcrumb: "Reporte de deudas",
            title: "Reporte de deudas",
        }
    },
    {
        path: "reporte-mora",
        name: "Reporte-mora",
        component: () => import("@/views/admin/ViewReporteMora.vue"),
        meta: {
            requiresAuth: true,
            breadcrumb: "Reporte de mora",
            title: "Reporte de Mora"
        }
    },
    {
        path: "busqueda-cuentas",
        name: "Busqueda-cuentas",
        component: () => import("@/views/admin/ViewSearchAccounts.vue"),
        meta: {
            requiresAuth: true,
            breadcrumb: "Busqueda de cuentas",
            title: "Busqueda de cuentas",
        }
    },
    {
        path: "sugerencias-usuarios",
        name: "Sugerencias-de-usuarios",
        component: () => import("@/views/admin/ViewSuggestionsUser.vue"),
        meta: {
            requiresAuth: true,
            breadcrumb: "Sugerencias de usuarios",
            title: "Buzón de sugerencias"
        }
    },
    {
        path: "administracion-usuarios",
        name: "Administracion-usuarios",
        component: () => import("@/views/admin/ViewUsers.vue"),
        meta: {
            requiresAuth: true,
            breadcrumb: "Administracion de usuarios",
            title: "Administracion de usuarios"
        }
    }
]
export {
    adminRoutes
}