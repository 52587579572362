const constancyRoutes = [
    {
        path: "solicitud-constancia",
        name: "Solicitud-constancia",
        component: () => import("@/views/user/ConstancyRequest/ViewConstancies.vue"),
        meta: {
            requiresAuth: true,
            breadcrumb: "Solicitud de constancias",
            title: "Solicitud de constancias",
        }
    }, {
        path: "solicitud-constancia-verificador",
        name: "Solicitud-constancia-verificador",
        component: () => import("@/views/user/ConstancyRequest/ViewRequestConstancies.vue"),
        meta: {
            requiresAuth: true,
            breadcrumb: "Solicitudes de constancias",
            title: "Solicitudes de constancias",
        }
    }, {
        path: "constancias-emitidas",
        name: "Constancias-emitidas",
        component: () => import("@/views/portfolio/ViewConstancies.vue"),
        meta: {
            requiresAuth: true,
            breadcrumb: "Constancias de no contribuyentes emitidas",
            title: "Constancias de no contribuyentes emitidas",
        }
    },
]
export {
    constancyRoutes
}

