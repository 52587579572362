import HTTP from "@/services/axios"
import { getStates } from "@/services/paths"


export default {
    state:{
        municipios:[]
    },
    mutations:{
        setMunicipios(state,data){
            state.municipios = data
        }
    },
    actions:{
        async getMunicipios({commit}){
            let response = await HTTP.get(getStates())

            const municipios = response.data.map((item) => {
                return {
                    value: item.ID_MUNICIPIO,
                    label: `${item.Municipio} - ${item.Departamento}`
                }
            })

            commit("setMunicipios", municipios)
        }
    }
}