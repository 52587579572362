import axios from "axios"
import { reportLog } from "@/services/paths";

export async function sendLog(type, message, environment) {
  try {
    await axios.post(reportLog(), {
      type: type,
      message: message,
      environment: environment,
    });

  } catch (err) {
    console.error("Error al enviar el log al backend:", err);
  }
}

/**
 * 
 * @param {Error|object|string} error 
 * @param {string} component 
 * @param {string} info 
 * @param {string|number} user 
 */
export function reportError(error, component, info, user = null) {
  let errorMessage;
  if (error instanceof Error) {
    errorMessage = `${error.name}: ${error.message}`;
  } else if (typeof error === 'object') {
    errorMessage = JSON.stringify(error);
  } else {
    errorMessage = String(error);
  }

  const errorInfo = {
    component: component,
    info: info,
    user: user,
  };

  sendLog('error', errorMessage, errorInfo);

  console.error(error, info);
}


export function reportInfo(message, info) {
  sendLog('info', message, {info: info})
  console.log(message, info)
}

export function reportWarning(message, info) {
  sendLog('warning', message, {info: info})
  console.warning(message)
}

/**
 * Handles global errors
 */
export function handleError(err, vm, info) {
  sendLog('error', err.message, {component: vm?.$options?.name, info: info})
  console.error(err, info);
}