import HTTP from "../../services/axios"
import { getVoucherHistory, setVoucherAsViewed, getSearchEmployees } from "../../services/paths";

export default {
    state: {
        vouchers: [],
        voucherDetail: {},
        employeSearch:[]
    },

    mutations: {
        setVouchers(state, data) {
            state.vouchers = data;
        },
        setVoucherDetail(state, data) {
            state.voucherDetail = data
        },
        setEmployeeSearch(state,data){
            state.employeSearch = data
        }
    },
    actions: {
        async fetchVoucherHistory({ commit }, id) {
            try {
                const response = await HTTP.get(getVoucherHistory(id));
                const data = response.data.data;
                commit("setVouchers", data);
            } catch (error) {
                console.error("setVouchers error:", error);
                throw error;
            }
        },
        showVoucherDetail({ commit }, data) {
            try {
                commit("setVoucherDetail", data);
            } catch (error) {
                console.error("setVoucherDetail error:", error);
                throw error;
            }
        },
        async fetchSearchEmployees({ commit },name) {
            try{
                const response = await HTTP.get(getSearchEmployees(name));
                commit("setEmployeeSearch",response.data.data)
            }catch(error){
                console.log(error);
            }
        },
        async setVoucherAsViewed({commit}, voucherId) {
            try {
                let response = await HTTP.put(setVoucherAsViewed(voucherId));
                if (!response.data.status) {
                    console.log("no visto")
                }
                
                // update vouchers
                response = await HTTP.get(getVoucherHistory(0));
                const data = response.data.data;
                commit("setVouchers", data);
            } catch (error) {
                console.error("setVoucherAsViewed error:", error);
                throw error;
            }
        }
    }
}
